.logo{
    height: 150px;
    width: 150px;
}

/* Sidebar.css */
/* Add these styles to your layout.css */

/* Optional: Adjustments for the fixed sidebar */
.fixed-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 200px; /* Adjust as needed */
  background-color: #fff; /* Adjust background color */
  z-index: 1000; /* Adjust z-index */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add box shadow */
  transition: transform 0.3s ease-in-out;
}

.fixed-sidebar.open {
  transform: translateX(0); /* Display the sidebar when open */
}

.fixed-sidebar ul {
  list-style: none;
  padding: 20px;
  margin: 0;
}

.fixed-sidebar li {
  margin-bottom: 15px;
}

/* Optional: Add styles for the active link */
.fixed-sidebar .nav-link.active {
  color: var(--themeRed);
}

.fixed-sidebar .nav-link:hover {
  background-color: transparent;
}

/* Optional: Adjustments for the toggle button */
.toggle-button {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1100; /* Place it above the sidebar */
  background-color: #fff; /* Adjust background color */
  border: none;
  padding: 10px;
  cursor: pointer;
}

/* Hide the toggle button on laptops and desktops */
@media (min-width: 992px) {
  .toggle-button {
    display: none;
  }
}

.nav-text-color{
  color: #6B4E70;
}


.nav-button-color{
  background-color: transparent;
  border-color: #24A7A4;
  color: #6B4E70;
}

.nav-button-color:hover{
  background-color: #6B4E70;
  border-color: #6B4E70;
  color: #fff;
}

/* Newsletter */

.newsletter-button{
  background-color: #24A7A4;
  border-color: #24A7A4;
  color: #fff;
}

.newsletter-button:hover{
  background-color: #24A7A4;
  border-color: #24A7A4;
  color: #fff;
}

.bg-newsletter{
  background-color: #6B4E70;
}

.nav-link, .cursor-pointer {
  cursor: pointer;
}


.footer-sm-text{
  font-size: xx-small !important;
}