/* Center the form vertically and horizontally */
.height-100vh {
  height: 100vh;
}

.login-wrapper {
  max-width: 400px;
  width: 100%;
}

/* Auth button styling */
.auth-button {
  background-color: #6B4E70;
  border-color: #6B4E70;
  color: #fff;
  border-radius: 10px;
  width: 180px;
}

.auth-button-container {
  display: flex;
  justify-content: center;
}

.auth-button:hover {
  background-color: transparent;
  border-color: #24A7A4;
  color: #6B4E70;
  width: 180px;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .col-lg-6.p-0 {
    background-image: none !important;
    height: 0% !important;
  }
}

/* Custom spinner color */
.custom-spin {
  color: #24A7A4 !important;
}

/* Background color for authentication form */
.bg-body-auth {
  background: whitesmoke;
}

/* Auth icon avatar styling */
.auth-icon-avatar {
  border-color: #6B4E70;
  color: #6B4E70;
}

/* Auth header text color */
.auth-header-text {
  color: #6B4E70 !important;
}
