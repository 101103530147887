

.bg-pending{
   background-color: #6B4E70;
   border-color: #6B4E70;
 }
 
 .bg-pending-orders{
  background-color: hsl(55, 98%, 42%);
  border-color: hsl(55, 98%, 42%);
 }
 
 .bg-scheduled{
   background-color: #24A7A4;
   border-color: #24A7A4;
 }
 
 .bg-delete{
   background-color: #009FBD;
   border-color: #009FBD;
 }
 
 .custom-spin {
   color: #24A7A4 !important;
 }
 
 .reminder-button{
   background-color: #24A7A4;
   border-color: #24A7A4;
   color: #fff;
 }
 
 .reminder-button:hover{
   background-color: transparent;
   border-color: #24A7A4;
   color: #6B4E70;
 }
 
 
 .reminder-button-color{
   background-color: #6B4E70 !important;
   border-color: #6B4E70 !important;
   color: #ffffff !important;
 }
 
 .reminder-button-color:hover{
   background-color: transparent !important;
   border-color: #6B4E70 !important;
   color: #6B4E70 !important;
 }
 